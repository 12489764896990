body {
  font-family: sans-serif;
}

/* -----Website Title on Search res pg----- */
#seach-res-title1{ 
  font-family: 'Risque';
  font-size: 20px;
}
#seach-res-title2{ 
  font-family: 'Risque';
  font-size: 24px;
}

#mobi-logo{ /* (small screen) <a> */
  margin-bottom: 12.5px;
}

#res-left-title-div{ /* (big screen) <a> */
  left: 2%;
  z-index: 9999;
  width: 50px;
  top: 14px;
}
/* -----/Website Title on Search res pg----- */

/* -----Search----- */
#search-div{ /* Home pg search <form> */
  border-radius: 25px;
  border: 1px solid #ced4da;
  margin: auto;
  padding-bottom: 0px;
}

#res-search-div{ /* search res search input field <form> */
  border-radius: 25px;
  border: 1px solid #ced4da;
  margin: auto;
  padding-bottom: 0px;
}

#main-search{ /* used for the search <input> */
  border-radius: 25px 0px 0px 25px;
  border: none;
  padding: 4px 0px 1px 15px;
  margin: 4px 0px 4px 0px;
  width: 86%;
  border-right: 1px solid #ced4da;
}

#main-search:focus { /* Removes the blue highlight when input is selected */
  outline-width: 0;
}
/* -----/Search----- */

/* -----Home pg Title + Search field----- */
.jumbotron { /* Title & search field <section> */
  display: flex;
  align-items: center;
  height: 400px;
}

.jumbotron h2 { /* Title */
  font-weight: 700;
  text-align: center;
  font-family: 'Risque';
}

@media (max-width: 35em) {
  .jumbotron h2 { 
      font-size: 55px;
  }
}
/* -----/Home pg Title + Search field----- */

/* /home */

footer {
  font-size: 12px;
  padding: 20px 0;
}

/* search item nav at bottom */
li {
  display: inline;
  
}
li span {
  margin: 10px 0px 10px 0px;
  font-size: 21px;
  color: #0280C8;
  padding: 4px 10px 4px 10px;
  display: inline;
  text-decoration: none;
  background-color: #F1F5F6;
}
.pg-nav-active:hover{
  color: #03659e;
  cursor: pointer;
}
/* /search item nav at bottom */

/* -----------------  Page Cards   ------------------ */

/* shows top Ad on Mobile/small web browser */
@media (max-width: 48em) {
  #top-ad{
    margin-top: 110px;
  }
}

@media (min-width: 1em) {
  #title-sec{
    padding-bottom: 10px;
  }
  #main-title{
    font-family: 'Risque', cursive;
    font-size: 6em;
  }
  #search-div{
    width: 100%;
  }
  #search-button{
    border-radius: 0px 25px 25px 0px;
    font-size: 20px;
    border: none;
    color: #0280C8;
    cursor: pointer;
    padding-top: 3px;
    padding-left: 6px;
    margin-bottom: 0px;
    margin-right: 0px;
  }
  #res-search-button{
    border-radius: 0px 25px 25px 0px;
    font-size: 20px;
    border: none;
    color: #0280C8;
    cursor: pointer;
    padding-top: 4px;
    padding-left: 6px;
    margin-right: 0px;
  }
  #ad-search-res{
    margin-top: 3.5px;
  }
  #top-ad{
    margin-bottom: 35px;
  }
  #botom-ad{
    margin-top: 35px;
  }
  #home-pg-cards{
    max-width: 325px;
  }
}
@media (min-width: 20em) {
  #main-search{
    width: 90%;
  }
  
}
@media (min-width: 30em) {
  #search-button{
    padding-top: 2.5px;
  }
  #res-search-button{
    padding-top: 2.5px;
    padding-left: 10px;
  }
  #main-search{
    width: 91%;
  }
  
}
@media (min-width: 36em) {
  #search-button{
    padding-top: 2.5px;
    padding-left: 9px;
  }
  #res-search-button{
    padding-top: 2.5px;
    padding-left: 10px;
  }
  
} 
@media (min-width: 48em) {

  #main-title{
    font-family: 'Risque', cursive;
    font-size: 8em;
  }
  #search-div{
    width: 35em;
  }
  #title-sec{
    left: 10%
  }
  #adj-4-logo{
    left: 10%;
    width: 90%;
    top: 65px;
    margin-bottom: 120px;
  }
  #res-search-div{
    margin-top: 7px;
    width: 88.5%;
    position: relative;
    left: -5.5%;
  }
  #title-sec{
    height: 70px;
  }
  #adj-hr{
    left: -11%;
    width: 111%;
    top: 69px;
  }
  #ad-search-res{
    margin-top: 40px;
  }
  #top-ad{
    margin-bottom: 35px;
  }
  #botom-ad{
    margin-top: 40px;
  }
  #home-pg-cards{
    max-width: 900px;
  }
} 

/* s */
.card-header{
  background-color: rgba(0,0,0,.045);
  font-size: 11px;
  padding: 0;
}
/* -----------------  /Page Cards   ------------------ */

/* Get Top Ad to show on moble */
/* @media (max-width: 42em) {
  #top-ad{ 
    padding-top: 100px;
  }
} */

.card{
  border-radius: 12.5px 12.5px 12.5px 12.5px;
}
.card-img-top{
  border-radius: 12.5px 12.5px 0px 0px;
}


/* this was appied to fix footer because it had an odd right margin that would not go away */
.container, .container-md, .container-sm {
  max-width: 100%;
}

/* footer */
/* -----This Is Very Important it makes sure footer is at the bottom of the page----- */
body {
  position: relative;
  min-height: 100vh;
}
#content-wrap {
  padding-bottom: 4rem;   
}
footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3rem;           
}
/* -----/This Is Very Important it makes sure footer is at the bottom of the page----- */


